import React, { useEffect, useState } from 'react';
import { PageBuilderContent } from '@Omegapoint/components/pageBuilderComponents';
import { GlobalStyle, Theme } from '@styles/global';

const PreviewPage = () => {
  const [previewContent, setPreviewContent] = useState(null);
  const [status, setStatus] = useState('none');

  useEffect(() => {
    if (status !== 'loading' && status !== 'connected') {
      let culture = '';
      if (window.location.hash.length > 0) {
        const parts = window.location.hash.split('=');
        culture = parts.length > 1 ? parts[1] : 'sv-SE';
      }

      const params = new URLSearchParams(window.location.search);
      const id = params.get('id');
      const key = params.get('key');

      setStatus('loading');
      async function fetchData() {
        const data = await fetch(
          `${process.env.GATSBY_CMS_PREVIEW_URL}/preview/content?startId=${id}&culture=${culture}&key=${key}`
        );

        const json = await data.json();
        setStatus('connected');
        setPreviewContent(json);
      }
      fetchData();

      let interval;
      setTimeout(() => {
        interval = setInterval(() => {
          fetchData();
        }, 5000);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [status]);

  if (previewContent === null || status === 'loading')
    return <div>Loading</div>;
  const cmsPage = previewContent.UmbracoContent.CmsPages[0];
  return (
    <Theme siteTheme="zeac">
      <GlobalStyle />
      <PageBuilderContent content={cmsPage.Items.Content}></PageBuilderContent>
    </Theme>
  );
};

export default PreviewPage;
